<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full sm:w-1/10 md:w-1/10 lg:w-1/5 xl:w-1/5">
        <statistics-card-line
          hideChart
          class="mb-base"
          icon="UserIcon"
          icon-right
          statistic="ชื่อบัญชี"
          :statisticTitle="profile.bank_fullname"
          color="success"
        />
      </div>

      <div class="vx-col w-full sm:w-1/10 md:w-1/10 lg:w-1/5 xl:w-1/5">
        <statistics-card-line
          hideChart
          class="mb-base"
          icon="ListIcon"
          icon-right
          statistic="เลขบัญชี"
          :statisticTitle="profile.bank_number"
          color="success"
        />
      </div>

      <!-- <div class="vx-col w-full sm:w-1/10 md:w-1/10 lg:w-1/5 xl:w-1/5">
        <statistics-card-line hideChart class="mb-base" icon="PhoneIcon" icon-right statistic="เบอร์"
          :statisticTitle="profile.bank_phone" color="success" />
      </div> -->

      <div class="vx-col w-full sm:w-1/10 md:w-1/10 lg:w-1/5 xl:w-1/5">
        <statistics-card-line
          hideChart
          class="mb-base"
          icon="DollarSignIcon"
          icon-right
          statistic="ยอดเงินเข้า"
          :statisticTitle="currency(sumTopup)"
          color="success"
        />
      </div>

      <div class="vx-col w-full sm:w-1/10 md:w-1/10 lg:w-1/5 xl:w-1/5">
        <statistics-card-line
          hideChart
          class="mb-base"
          icon="DollarSignIcon"
          icon-right
          statistic="ยอดเงินออก"
          :statisticTitle="currency(sumWithdraw)"
          color="danger"
        />
      </div>

      <div class="vx-col w-full sm:w-1/10 md:w-1/10 lg:w-1/5 xl:w-1/5">
        <statistics-card-line
          hideChart
          class="mb-base"
          icon="DollarSignIcon"
          icon-right
          statistic="ยอดคงเหลือ"
          :statisticTitle="currency(balance)"
          color="success"
        />
      </div>
    </div>

    <div class="vx-card p-6">
      <div class="flex flex-wrap items-center">
        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <div class="vx-card__title">
            <h4>เลือกวันที่</h4>
            <br />
          </div>
          <flat-pickr v-model="date" />
          <p class="mt-4">Selected Date: {{ date }}</p>
        </div>
      </div>

      <vs-table
        stripe
        max-items="20"
        pagination
        :data="transactionItems"
        search
      >
        <template slot="thead">
          <vs-th>วัน/เวลา</vs-th>
          <vs-th>ฝาก/ถอน</vs-th>
          <vs-th>USERNAME</vs-th>
          <vs-th>ยอดเงิน</vs-th>
          <vs-th>ข้อมูล</vs-th>
          <vs-th>Admin</vs-th>
          <vs-th>ดูสลิป</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr.datetime">
              {{ moment(tr.datetime).format("YYYY-MM-DD HH:mm:ss") }}
            </vs-td>

            <vs-td :data="tr.type">
              <p v-if="tr.type === 'deposit'" class="text-success">ฝาก</p>
              <p v-else-if="tr.type === 'withdraw'" class="text-danger">ถอน</p>
              <p v-else class="text-warning">{{ tr.type }}</p>
            </vs-td>

            <vs-td class="text-danger">
              <router-link :to="`/member/${data[indextr].username}`"
                >{{ data[indextr].username }}
              </router-link>
            </vs-td>

            <vs-td :data="tr.amount">
              {{ currency(tr.amount) }}
            </vs-td>

            <vs-td :data="tr.info">
              {{ tr.info }}
            </vs-td>

            <vs-td :data="tr.admin">
              {{ tr.admin }}
            </vs-td>

            <vs-td :data="tr.info">
              <vs-td v-if="tr.info.includes('deposit')">
                <vs-button
                  size="small"
                  color="primary"
                  type="border"
                  icon-pack="feather"
                  icon="icon-search"
                  target
                  :href="{ url: urlaws3 + 'topup/' + tr.id + '.jpg' }"
                ></vs-button>
              </vs-td>
              <vs-td v-else-if="tr.info.includes('withdraw')">
                <vs-button
                  size="small"
                  color="primary"
                  type="border"
                  icon-pack="feather"
                  icon="icon-search"
                  target
                  :href="{ url: urlaws3 + 'withdraw/' + tr.id + '.jpg' }"
                ></vs-button>
              </vs-td>
              <vs-td v-else-if="tr.type === 'withdraw' && tr.amount >= 30000">
                <vs-button
                  size="small"
                  color="primary"
                  type="border"
                  icon-pack="feather"
                  icon="icon-search"
                  target
                  :href="{ url: urlaws3 + 'withdraw/' + tr.id + '.jpg' }"
                ></vs-button>
              </vs-td>
              <vs-td v-else-if="tr.type === 'withdraw' && tr.info == 'ถอนมือ'">
                <vs-button
                  size="small"
                  color="primary"
                  type="border"
                  icon-pack="feather"
                  icon="icon-search"
                  target
                  :href="{ url: urlaws3 + 'withdraw/' + tr.id + '.jpg' }"
                ></vs-button>
              </vs-td>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </div>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import vSelect from 'vue-select'
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import axios from '../../axios'
import moment from 'moment'

export default {
  components: {
    vSelect,
    flatPickr,
    StatisticsCardLine
  },
  data () {
    return {
      urlaws3: process.env.VUE_APP_AWS3_URL,
      date: moment.tz('Asia/Bangkok').format('YYYY-MM-DD'),
      profile: {
        bank_fullname: '',
        bank_number: '',
        bank_phone: ''
      },
      sumTopup: 0,
      sumWithdraw: 0,
      balance: 0,
      transactionItems: []
    }
  },
  watch: {
    date (newValue) {
      this.date = newValue
      this.getData()
    }
  },
  async mounted () {
    await this.getData()
  },
  methods: {
    async getData () {
      const dataProfiles = await axios.get(
        `bankwithdraw/list/profile/${this.$route.params.bank}/${this.$route.params.id}`
      )
      const dataTransactions = await axios.get(
        `bankwithdraw/list/transactions/${this.$route.params.bank}/${this.$route.params.id}/${this.date}`
      )
      //   console.log(dataProfiles)
      if (dataProfiles.data.status) {
        this.profile.bank_fullname = dataProfiles.data.data[0].bank_fullname
        this.profile.bank_number = dataProfiles.data.data[0].bank_number
        this.profile.bank_phone = dataProfiles.data.data[0].bank_phone
      }

      if (dataTransactions.data.status) {
        this.transactionItems = dataTransactions.data.data.transactions
        this.sumTopup = dataTransactions.data.data.sumTopup
        this.sumWithdraw = dataTransactions.data.data.sumWithdraw
        this.balance = dataTransactions.data.data.balance
      }
    },
    currency (amount) {
      //SET NULL OR NaN = 0
      if (!amount || amount === 'NaN') {
        amount = 0
      }
      if (amount >= 0) {
        if (typeof amount === 'number') {
          amount = String(Math.abs(amount))
        }
        return amount.replace(/\d(?=(\d{3})+\.)/g, '$&,')
      } else {
        amount = String(Math.abs(amount))
        amount = amount.replace(/\d(?=(\d{3})+\.)/g, '$&,')
        // console.log(amount)
        return `-${amount}`
      }
    }
  }
}
</script>

<style></style>
